@import "styles/_variables";

.modal {
  position: relative;
  background: white;
  border-radius: 16px;
  max-width: 572px;
  outline: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05), 0px 0px 80px rgba(0, 0, 0, 0.1);
  max-height: 90%;
}

.modalScroll {
  overflow-y: scroll;
}

.modalLarge {
  padding: $modal-medium-size-paddings;
}

.modalSmall {
  padding: 24px;
}

.authModalLarge {
  padding: 32px 0 0 0;
}

.modalSmall {
  padding: 24px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }
}

.modalContent {
}

/* 
 * Transitions
 */

:global {
  .ReactModalPortal {
    /* Modal */
    .ReactModal__Content {
      transition: all 0.8s $easeOutQuart;
      overflow-y: auto;
    }
    .ReactModal__Content--after-open {
      transition-delay: 0.1s;
      opacity: 1;
      transform: translateY(0);
    }
    .ReactModal__Content--before-close {
      opacity: 0;
      transform: translateY(30px);
    }

    /* Overlayer */
    .ReactModal__Overlay {
      transition: background 0.8s $easeOutQuart;
      z-index: $z-layers-modals;
    }
    .ReactModal__Overlay--after-open {
      background: rgba(0, 0, 0, 0.8);
    }

    .ReactModal__Overlay--before-close {
      background: rgba(0, 0, 0, 0);
    }
  }
}

/* 
 * Tablet 
 */
@media (max-width: $media--tablet-portrait) {
  .modal {
    max-width: 514px;
  }
}

/* 
 * Mobile View
 */
@media (max-width: $media--phone) {
  .modal {
    max-width: 350px;
    width: calc(100% - 40px);
  }
  .modalLarge {
    padding: $modal-small-size-paddings;
  }
  .authModalLarge {
    padding: 24px 0 0 0;
  }
}