@import "styles/_variables";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-layers-loading-screen;
  background: white;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 32px;
    height: auto;
  }
}
