@import "styles/_variables";

.container {
  padding-top: 40px;
}

.text {
  composes: textMedium from "styles/global/_typography.scss";
  text-align: center;
  font-size: 12px;
  margin-top: 0;

  a {
    color: $color-black;
    font-weight: bold;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
