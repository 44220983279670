@import "styles/_variables";

.modalBody {
  text-align: center;
}

.icon {
  width: 40px;
  height: auto;
  margin: 0 auto;
  display: block;
  path {
    fill: black;
  }
}

.title {
  text-align: center;
  margin: 0 0 8px 0;
  composes: titleMedium from "styles/global/_typography.scss";
}

.description {
  text-align: center;
  margin: 0 0 32px 0;
  line-height: 24px;
  composes: textMedium from "styles/global/_typography.scss";

  strong {
    font-weight: 600;
  }
}

div.emailInput {
  margin-bottom: 16px;
}

.loading {
  margin: 0 auto;
  display: block;
}

.editAgainButton {
  display: inline-flex;
}

/* 
 * Mobile View
 */
@media (max-width: $media--phone) {
}
