@import "styles/_variables";

.main {
  min-height: 100%;
  flex-grow: 1;
  width: 90%;
  max-width: $grid-large-max-width;
  margin: 0 auto;
}

/*
 * Medium Size
 */
@media (max-width: $media--size-medium-max) {
}

/*
 * Small Size
 */
@media (max-width: $media--size-small-max) {
  .main {
    width: calc(100% - 20px * 2);
  }
}
