/**
 * Layout
 */
$layout-corner-radius-small: 4px;
$layout-corner-radius-medium: 8px;
$layout-corner-radius-large: 16px;

$box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05), 0px 0px 80px rgba(0, 0, 0, 0.1);
$modal-medium-size-paddings: 32px 40px 40px 40px;
$modal-small-size-paddings: 24px;

/**
 * Colors
 */
$color-hl-blue: #005aeb;
$color-blue: #005aeb;
$color-blue-darker: #0051d4;
$color-blue-600: #1453B8;

$color-red: #df0000;
$color-red-darker: #df0000;
$color-red-600: #B21A1A;

$color-white: #fff;
$color-white-60: rgba(255, 255, 255, 0.6);
$color-gray-1: #f5f5f5;
$color-gray-3: #f5f5f5;
$color-gray-3-darker: #efebeb;

$color-black: #000;
$color-black-50: rgba(0, 0, 0, 0.5);
$color-black-60: rgba(0, 0, 0, 0.6);
$color-black-10: #e5e5e5;

$color-twitter: #4999e9;
$color-facebook: #1877f2;

/**
 * z-index layers index
 */
$z-layers-loading-screen: 100;
$z-layers-modals: 20;
$z-layers-profile-sidebar: 10;

/**
 * Grid column
 */
$grid-large-max-width: 1012px;

/**
 * Vertical spacing
 */
$vertical-spacing-default-1: 2px;
$vertical-spacing-default-2: 4px;
$vertical-spacing-default-3: 6px;
$vertical-spacing-default-4: 8px;
$vertical-spacing-default-5: 12px;
$vertical-spacing-default-6: 16px;
$vertical-spacing-default-7: 20px;
$vertical-spacing-default-8: 24px;
$vertical-spacing-default-9: 32px;
$vertical-spacing-default-10: 40px;
$vertical-spacing-default-11: 48px;
$vertical-spacing-default-12: 56px;
$vertical-spacing-default-13: 64px;
$vertical-spacing-default-14: 80px;
$vertical-spacing-default-15: 96px;
$vertical-spacing-default-16: 120px;

$vertical-spacing-small-1: 2px;
$vertical-spacing-small-2: 4px;
$vertical-spacing-small-3: 6px;
$vertical-spacing-small-4: 8px;
$vertical-spacing-small-5: 12px;
$vertical-spacing-small-6: 16px;
$vertical-spacing-small-7: 16px;
$vertical-spacing-small-8: 18px;
$vertical-spacing-small-9: 24px;
$vertical-spacing-small-10: 30px;
$vertical-spacing-small-11: 36px;
$vertical-spacing-small-12: 42px;
$vertical-spacing-small-13: 48px;
$vertical-spacing-small-14: 60px;
$vertical-spacing-small-15: 72px;
$vertical-spacing-small-16: 90px;

/**
 * Responsive breakpoints
 * @todo: clean up breakpoints
 */
$media--phone: 767px;
$media--tablet-portrait: 768px;
$media--tablet: 1024px;

$media--size-small-max: 767px;
$media--size-medium-max: 959px;

/**
 * Animation Easings
 */
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
