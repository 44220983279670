p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

h1,
.titleLarge {
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  font-family: "Degular Display";
}

h2,
.titleMedium {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  font-family: "Degular Display";
}

h3,
.titleSmall {
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  font-family: "Degular Display";
}

h4,
.textLarge {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
}

h5,
.textMedium {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

h6,
.textSmall {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.labelSmall {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}
