@import "styles/_variables";

.button {
  position: absolute;
  right: 12px;
  top: 12px;

  svg {
    position: relative;
    z-index: 1;
  }

  &::after {
    transition: transform 0.6s $easeOutQuart;

    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: black;
    border-radius: 50%;
    z-index: 0;
    transform: scale(0.9);
  }
}
